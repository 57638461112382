<template>
  <div class="main-panel main-panel_login py-3">

    <div class="content align-items-center my-3">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-5 col-md-5">
                    <div class="card">
                        <div class="content">
                            <div class="row m-4">
                                    <div class="col-md-12">
                                        <div class="card-description color-text-phila text-center">
                                          <img src="assets/img/img2.png" alt="" srcset="" class="img-fluid" style="width:40%">
                                            <!-- <img src="assets/img/Phila-3-1024x1024.png" alt="" srcset="" class="img-fluid" style="width:20%;"> -->
                                            <br>
                                            Phila Cité d'exaucement
                                            <small>Ministère de prise en charge sociale</small>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="text-center">
                                            <div class="header">
                                            </div>
                                            <div class="content">
                                                <h4 class="title">Administration</h4>
                                                <form class="mx-4 text-left my-4" @submit.prevent="login">
                    
                                                    <div class="row justify-content-center">
                                                        <div class="col-md-10">
                                                            <div class="form-group">
                                                                <label>Téléphone <span class="text-danger">*</span></label>
                                                                <input type="text" v-model="this.form.user_pseudo" class="form-control border-input" placeholder="Téléphone">
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="exampleInputEmail1">Mot de passe <span class="text-danger">*</span></label>
                                                                <input type="password" v-model="this.form.user_password" class="form-control border-input" placeholder="Mot de passe">
                                                            </div>
                                                        </div>
                                                    </div>
                    
                                                    <div class="text-center">
                                                        <button v-if="!this.lottieloading" type="submit" class="btn btn-danger btn-fill btn-wd">Connexion</button>
                                                        <div class="text-center">
                                                          <lottie-player v-if="this.lottieloading" class="text-center"
                                                            :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'" background="transparent" speed="1" style="width: 100%; height: 90px;" loop
                                                            autoplay></lottie-player>
                                                          <span v-if="this.lottieloading && this.step_last" class="small">Traitement en cours</span>
                                                        </div>
                                                    </div>

                                                    <div class="clearfix"></div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>


   <footer class="footer">
    <div class="container">
      <div class="row align-items-enter">
        <div class="col-md-6 text-center">
          <nav>
            <ul>
              <li>
                <div class="d-md-flex align-items-center text-center">
                  <img src="assets/img/Phila-3-1024x1024.png" alt="" srcset="" class="img-fluid mr-2" style="width:5%">
                  <a href="https://ce.church" class="mt-md-1">
                    Phila c.e
                  </a>
                </div>
              </li>
              <!-- <li>
                <a href="">
                  Infos
                </a>
              </li> -->
            </ul>
          </nav>
        </div>
        <div class="col-md-6 text-center">
          <div class="copyright">
            &copy; {{ new Date().getFullYear() }}, produit par <a href="https://ce.church"
              class="category text-dark" style="color: #66615b;">Phila c.e</a>
          </div>
        </div>
      </div>
    </div>
  </footer>

  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'LoadPage',

  data() {
    return {
      user: null,
      form:{
        user_pseudo:null,
        user_password:null,
      },
      requesting: false,
      step_last: false,
      lottieloading: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    // this.gb_taux();
    
    setInterval(() => {
      // this.gb_taux();
    }, 60000);
  },
  methods: {

    async login() {
      if(this.form.user_pseudo.trim() !=null && this.form.user_pseudo.toString().trim() !=null && this.form.user_password.trim() !=null && this.form.user_password.toString().trim() !=null) {
      //  var mod = this.modules.find((mod)=>mod.id.toString().trim() == mod_id.toString().trim());
        this.lottieloading = true;
        this.step_last = true;

        await axios.post(this.$store.state.app_url_dev + "login",this.form).then(({ data }) => {
        if (data.status == 1) {
          // var message = "Bonjour " + (this.form.profiler_sex.toString().trim() == "M" ? "Mr " : "Mme ") + (this.form.names.toString().trim().toUpperCase()) + ".\n Votre souscription vient d'être fait pour le projet 7000 Partenaires. (Phila-c.e)";


          // let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());

            // axios.get(urlgetsms).then(() => { });
            this.$store.commit('AUTHENTIFICATE_USER',data.data);
            localStorage.setItem('AUTHENTIFICATE_USER',JSON.stringify(data.data));
            this.$store.state.user_auth = data.data;

            setTimeout(() => {
              this.lottieloading = false;
              this.step_last = false;
              // this.$router.push({name:'Dashboard'})
              this.$router.push({ path: '/dashboard' })
              .then(() => { this.$router.go() })


              // const url = new URL('/dashboard', window.location.origin)
              // window.location.href = url.toString()
            }, 4500);
          } else {
            // this.toast.error(data.message, {
            //   timeout: 6000
            // });
          }
        }).catch(function (error) {
        console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });
      }else{
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    async storeSouscripteur() {
      
      if(this.form.self_souscription !=null ){
        this.form.type_souscription_id = 2;
      }else{
        this.form.type_souscription_id = 1;
      }

      if(this.form.names.trim() !=null && this.form.names.toString().trim() !=null && this.form.profiler_phone.trim() !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation.trim() !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex.trim() !=null && this.form.profiler_sex.toString().trim() !=null) {
      //  var mod = this.modules.find((mod)=>mod.id.toString().trim() == mod_id.toString().trim());

        await axios.post(this.$store.state.app_url_dev + "souscripteur",this.form).then(({ data }) => {
        if (data.status == 1) {
          var message = "Bonjour " + (this.form.profiler_sex.toString().trim() == "M" ? "Mr " : "Mme ") + (this.form.names.toString().trim().toUpperCase()) + ".\n Votre souscription vient d'être fait pour le projet 7000 Partenaires. (Phila-c.e)";


          let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());
          // let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=test&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());

          axios.get(urlgetsms).then(() => { });
          setTimeout(() => {
            this.form.step_one = false;
            this.form.step_two = true;
            this.lottieloading = false;
            this.reseRefreshForm();

            this.settiming = setInterval(() => {
              this.newsSouscripteur();
            }, 6000);
            
          }, 4500);
          } else {
            // this.toast.error(data.message, {
            //   timeout: 6000
            // });
          }
        }).catch(function (error) {
        console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });
      }else{
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    to_submit_form() {
      // if (this.form.names.toString().trim() !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex.toString().trim() !=null) {
      if(this.form.names !=null && this.form.names.toString().trim() !=null && this.form.profiler_phone !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex !=null && this.form.profiler_sex.toString().trim() !=null) {
        this.lottieloading = true;
        // if (this.Online()) {
          this.storeSouscripteur();
          // this.RegisterDemandUser();
           // } else {
        //     setTimeout(() => {
        //     this.lottieloading = false
        //   }, 2500);
        // } 
      } else {
        this.checkForm = true;
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    reseRefreshForm() {
      this.form.names = null;
      this.form.profiler_etat_civil = "Non défini";
      this.form.profiler_phone = null;
      this.form.profiler_email = null;
      this.form.profiler_sex = null;
      this.form.devise_id = null;
      this.form.projet_souscripteur_price_estimation = null;
      this.form.city_name = null;
      this.form.type_souscription_id = null;
      this.form.self_souscription = null;
    },

    newsSouscripteur() {
      clearInterval(this.settiming);
      this.reseRefreshForm();
      this.form.step_one = true;
      this.form.step_two = false;
      this.lottieloading = false;
      this.settiming = null;
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },

    getNumberFormatSms(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value.substring(1, value.length);
        } else if (value.length == 12) {
          return  value;
        } else if (value.length == 10) {
          return '243' + value.substring(1, value.length);
        }
      }
    },
  }
}
</script>
