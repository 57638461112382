<template>
  <div>
    <navbar-globale></navbar-globale>


    <div class="main-panel">
        <navbar-globale-mobile></navbar-globale-mobile>

        <div class="content">
          <div class="container-fluid">
              <div class="row">
                <div v-if="this.projetActif !=null && this.projetActifPayments !=null && !this.step_store && !this.lottieloading && !this.step_load" class="col-md-12">
                    <div class="card">
                        <div class="header">
                            <div class="row">
                                <div class="col-md-9">
                                    <h4 class="title">Rapport</h4>
                                    <p class="category"> {{this.projetActifPayments.length}} Paiements au total</p>
                                </div>
                                <div  v-if="this.$store.state.tauxes != null && this.$store.state.tauxes.length > 0" class="col-md-3 text-right">
                                  <p class="category">
                                  <strong v-if="this.gb_devise_one_to_go_convert_two('usd', 'cdf').length > 0 && this.$store.state.tauxes != null" class="text-danger">1USD {{ this.gb_separateur_milliers_chiffre_grp_3(this.gb_devise_one_to_go_convert_two('usd', 'cdf')[0].taux_price) }} CDF</strong> <br/>
                                  <strong v-if="this.gb_devise_one_to_go_convert_two('usd', 'euro').length > 0 && this.$store.state.tauxes != null" class="text-danger">1USD {{ this.gb_separateur_milliers_chiffre_grp_3(this.gb_devise_one_to_go_convert_two('usd', 'euro')[0].taux_price) }} EURO</strong> <br/>
                                  </p>
                                </div>
                                <!-- <div class="col-md-3">
                                    <button class="btn btn-default btn-block" data-toggle="modal" data-target=".modal-notif" @click.prevent="this.step_store=true">Mise à jour paiement</button>
                                </div> -->
                            </div>

                        </div>
                        <div class="content table-responsive table-full-width">
                          <div class="row justify-content-center align-items-center" style="margin-bottom:3%;display: flex;">
                            <div class="col-md-10">
                              <div class="row justify-content-center align-items-center">
                                <div class="col-md-12">
                                  <div class="row justify-content-center align-items-center" style="margin-bottom:3%;display: flex;">
                                    <div class="col-md-6 text-center border border-danger">
                                      <div class="row">
                                        <div class="col-12">
                                          <span class="text-uppercase font-weight-bold">Global</span>  <br/>
                                        </div>
                                        <div class="col-12">
                                          <div class="row justify-content-center align-items-center"  style="margin-bottom:3%;display: flex;">
                                            <div class="col-md-5">
                                              <span class="text-uppercase small">effectués</span>  <br/>
                                              <span class="h6 text-danger"> $ {{ this.gb_separateur_milliers_chiffre_grp_3(this.total_globale_souscritPay_souscripteurs) }}</span>
                                            </div>
                                            <div class="col-md-5 offset-md-1" style="border-left: 1px solid darkgray;">
                                              <span class="text-uppercase text-secondary small">attendus</span>  <br/>
                                              <span class="h6 text-secondary"> $ {{ this.gb_separateur_milliers_chiffre_grp_3(parseFloat(13875).toFixed(2)) }}</span>
                                              <!-- <span class="h6 text-secondary"> $ {{ parseFloat(parseInt(this.total_souscripteurs_7kp.length)* 1000).toFixed(2) }}</span> -->
                                            </div>
                                          </div>
                                        </div>
                                          <div class="col-12" style="margin-top:1.5%;">
                                            <span class="text-secondary category">{{ parseFloat((parseInt(this.total_globale_souscritPay_souscripteurs) * 100 ) / (parseInt(13875))).toFixed(2) }}   % de paiement éffectués </span>
                                            <!-- <span class="text-secondary category">{{ parseFloat((parseInt(this.total_globale_souscritPay_souscripteurs) * 100 ) / (parseInt(this.total_souscripteurs_7kp.length) * 1000)).toFixed(2) }}   % de paiement éffectués </span> --> 
                                            <!-- <br>
                                                <span class="text-secondary category">{{ this.total_souscripteurs_7kp.length }} {{ this.total_souscripteurs_7kp.length > 1 ? "souscripteurss" : "souscripteur" }}</span> -->
                                          </div>
                                      </div>
                                    </div>
                                  
                                    <div class="col-md-6 text-center border border-danger" style="display: none !important;">
                                      <div class="row">
                                        <div class="col-12">
                                          <span class="text-uppercase font-weight-bold">Partiel</span>  <br/>
                                          <!-- <span class="h6 text-danger"> $ {{ this.total_souscritPay_souscripteurs }}</span> -->
                                        </div>
                                        <div class="col-12">
                                          <div class="row justify-content-center align-items-center"  style="margin-bottom:3%;display: flex;">
                                            <div class="col-md-5">
                                              <span class="text-uppercase small">effectués</span>  <br/>
                                              <span class="h6 text-danger"> $ {{ this.gb_separateur_milliers_chiffre_grp_3(this.total_souscritPay_souscripteurs) }}</span>
                                            </div>
                                            <div class="col-md-5 offset-md-1" style="border-left: 1px solid darkgray;">
                                              <span class="text-uppercase text-secondary small">attendus</span>  <br/>
                                              <span class="h6 text-secondary"> $ {{   this.gb_separateur_milliers_chiffre_grp_3(parseFloat(parseInt(13875 / 5)).toFixed(2))  }}</span>
                                              <!-- <span class="h6 text-secondary"> $ {{ this.total_souscripteurs_7kp }}</span> -->
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-12" style="margin-top:1.5%;">
                                          <span class="text-secondary category">{{ parseFloat((parseInt(this.total_souscritPay_souscripteurs) * 100 ) / (parseInt(13875 / 5))).toFixed(2) }}   % de paiement éffectués</span>
                                          <!-- <span class="text-secondary category">{{ parseFloat((parseInt(this.total_souscritPay_souscripteurs) * 100 ) / parseInt(this.total_souscripteurs_7kp)).toFixed(2) }}   % de paiement éffectués</span> -->
                                                <br>
                                          <span v-if="this.total_souscripteur_payment.length >= 1" class="text-secondary category">{{ this.total_souscripteur_payment.length }} {{ this.total_souscripteur_payment.length > 1 ? "souscripteurs" : "souscripteur" }}</span>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="row justify-content-center align-items-center"  style="margin-bottom:3%;display: flex;">
                                    <div class="col-md-5">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <span class="category"> Rechercher souscripteur </span>
                                          </div>
                                          <div class="col-md-12">
                                            <input v-model="this.souscritPay_search" type="text" class="form-control border-input" placeholder="Rechercher souscripteur">
                                          </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <span class="category">Periode de paiement </span>
                                        </div>
                                        <div class="col-md-12">
                                            <select v-model="this.souscritPay_search_mounth" :id="'periodicity_' + this.projetActif?.projet_coding" class="form-control border-input">
                                              <option value=""></option>
                                              <option  v-for="(periode, index) in projetActif?.periodicities.sort(function (a, b) { return a.id - b.id; })" :key="index" :value="periode.periodicity_title">{{ periode.periodicity_title.toUpperCase() }}</option>
                                            </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-3">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <span class="category">Date de paiement </span>
                                        </div>
                                        <div class="col-md-12">
                                          <input  type="date" v-model="this.souscritPay_search_date" class="form-control border-input" >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div class="row justify-content-center align-items-center my-3" style="margin-bottom:3%;display: flex;">
                            <div class="col-md-9">
                              <h4 class="title">Les paiements souscripteurs</h4>
                              <p class="category"> {{ this.search_Pay_souscripteurs.length }} Paiements au total</p>
                            </div>
                          </div>
                          <div v-if="this.search_Pay_souscripteurs.length > 0" class="row justify-content-center align-items-center  my-3" style="display: flex;">
                            <div class="col-md-9" style="height-max:450px; overflow-y: auto;">
                              <table class="table table-striped table-hover">
                                  <thead>
                                      <th>ID</th>
                                    <th>Souscripteur</th>
                                    <th>Montant</th>
                                    <th>Periode</th>
                                    <th>Date Paiem.</th>
                                  </thead>
                                  <tbody>
                                      <!-- <tr v-for="(payment, index) in projetActifPayments.slice(0,10)" :key="index" class="col"> -->
                                      <tr v-for="(payment, index) in search_Pay_souscripteurs" :key="index" class="col">
                                        <td>{{index + 1}}</td>
                                        <td> 
                                          <a href="" class="text-dark"  style="color:#1C1B1B;">{{ payment?.souscripteur?.identity?.profiler_prenom }} {{ (payment?.souscripteur?.identity?.profiler_nom != null && payment?.souscripteur?.identity?.profiler_nom != "Non défini" ? payment?.souscripteur?.identity?.profiler_nom : '') }}</a>
                                          <br>
                                          <strong class="small" v-if="payment?.souscripteur?.identity?.profiler_code !=null && payment?.souscripteur?.identity?.profiler_code.trim() !=''">{{ payment?.souscripteur?.identity?.profiler_code}}</strong>
                                        </td>
                                        <!-- <td>{{this.gb_capitalisingFormat(payment?.souscripteur?.identity?.profiler_prenom)}} {{this.gb_capitalisingFormat(payment?.souscripteur?.identity?.profiler_nom)}} {{ payment?.souscripteur?.identity?.profiler_postnom !=null ? this.gb_capitalisingFormat(payment?.souscripteur?.identity?.profiler_postnom):' '}}</td> -->
                                        <td>{{payment?.devise?.devise_short}} {{ this.gb_separateur_milliers_chiffre_grp_3(parseFloat(payment?.payment).toFixed(2))}}</td>
                                        <td>{{payment?.periodicity?.periodicity_title }}</td>
                                        <td>{{payment?.payment_date.toString().substring(0,10)}}</td>
                                      </tr>
                                      <!-- <tr class="col">
                                        <td>2</td>
                                        <td>Minerva Hooper</td>
                                        <td>$23,789</td>
                                        <td>Curaçao</td>
                                      </tr> -->
                                  </tbody>
                              </table>
                            </div>
                          </div>

                            <div v-else class="row justify-content-center align-items-center my-3" style="margin-bottom:3%;display: flex;">
                              <div class="col-md-9">
                                <p class="category">Pas des paiements disponibles après filtrage</p>
                              </div>
                            </div>

                          <hr>
                          <div class="row justify-content-center align-items-center my-3" style="margin-bottom:3%;display: flex;">
                            <div class="col-md-9">
                              <h4 class="title text-dark">Les paiements  souscripteurs en attentes</h4>
                              <p class="category"> {{ this.search_souscritNonPay_souscripteurs.length }} souscripteurs en  attente de paiement</p>
                            </div>
                          </div>
                          <div v-if="this.search_souscritNonPay_souscripteurs.length > 0" class="row justify-content-center align-items-center  my-3" style="margin-bottom:5%;display: flex;">
                            <div class="col-md-9 mb-3" style="height-max:450px; overflow-y: auto;">
                              <table class="table table-striped table-hover">
                                  <thead>
                                      <th>ID</th>
                                    <th>Souscripteur</th>
                                    <th>Montant Attendu</th>
                                    <th>Contact</th>
                                  </thead>
                                  <tbody>
                                      <!-- <tr v-for="(payment, index) in projetActifPayments.slice(0,10)" :key="index" class="col"> -->
                                      <!-- <tr v-for="(payment, index) in search_Pay_souscripteurs.slice(0, 10)" :key="index" class="col"> -->
                                      <tr v-for="(souscripteur, index) in search_souscritNonPay_souscripteurs" :key="index" class="col">
                                        <td>{{ index + 1 }}</td>
                                        <td> 
                                          <!-- <a href="" class="text-dark"  style="color:#1C1B1B;">{{ payment?.souscripteur?.identity?.profiler_prenom }} {{ payment?.souscripteur?.identity?.profiler_nom }}</a> -->
                                          <a href="" class="text-dark"  style="color:#1C1B1B;">{{ souscripteur?.identity?.profiler_prenom }} {{ (souscripteur?.identity?.profiler_nom != null && souscripteur?.identity?.profiler_nom != "Non défini" ? souscripteur?.identity?.profiler_nom : '') }}  {{ (souscripteur?.identity?.profiler_postnom != null && souscripteur?.identity?.profiler_postnom != "Non défini" ? souscripteur?.identity?.profiler_postnom : '') }}</a>
                                          <br>
                                          <strong class="small" v-if="souscripteur?.identity?.profiler_code !=null && souscripteur?.identity?.profiler_code.trim() !=''">{{ souscripteur?.identity?.profiler_code }} </strong>
                                        </td>
                                        <!-- <td>{{this.gb_capitalisingFormat(payment?.souscripteur?.identity?.profiler_prenom)}} {{this.gb_capitalisingFormat(payment?.souscripteur?.identity?.profiler_nom)}} {{ payment?.souscripteur?.identity?.profiler_postnom !=null ? this.gb_capitalisingFormat(payment?.souscripteur?.identity?.profiler_postnom):' '}}</td> -->
                                        <!-- <td>{{ payment?.devise?.devise_short }} {{ parseFloat(payment?.payment).toFixed(2) }}</td> -->
                                        <!-- <td>{{ payment?.periodicity?.periodicity_title }}</td> -->
                                        <td>{{ souscripteur?.devise?.devise_short }}{{ (souscripteur?.projet_souscripteur_price_estimation).toFixed(2) }}</td>
                                        <td>{{ souscripteur?.identity?.profiler_phone }}</td>
                                        <!-- <td>{{ payment?.payment_date.toString().substring(0, 10) }}</td> -->
                                      </tr>
                                      <!-- <tr class="col">
                                      <td>2</td>
                                      <td>Minerva Hooper</td>
                                      <td>$23,789</td>
                                      <td>Curaçao</td>
                                    </tr> -->
                                  </tbody>
                              </table>
                            </div>
                          </div>
                          <div v-else class="row justify-content-center align-items-center my-3" style="margin-bottom:3%;display: flex;">
                            <div class="col-md-9">
                                <p class="category">Pas des souscripteurs en attente de paiement</p>
                            </div>
                          </div>
                          <div v-if="this.search_souscritNonPay_souscripteurs.length > 0" class="row justify-content-center align-items-center  my-3" style="margin-bottom:5%;display: flex;">
                            <div class="col-12 text-right float-right  mb-3">
                                  <a @click.prevent="notificateRappel"  target="_blank" href="" class="btn btn-round btn-fill btn-info float-right">Emettre un rappel</a>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="text-center">
                    <lottie-player v-if="this.lottieloading && this.step_load" class="text-center"
                      :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'" background="transparent" speed="1" style="width: 100%; height: 90px;" loop
                      autoplay></lottie-player>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <footer-globale></footer-globale>
    </div>
  </div>
</template>

<script>
import NavbarGlobale from './layouts/NavBar.vue';
import NavbarGlobaleMobile from './layouts/NavBarMobile.vue';
import FooterGlobale from './layouts/FooterGlobale.vue';

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'PaymentPage',
  components:{NavbarGlobale,FooterGlobale,NavbarGlobaleMobile},
  data() {
    return {
      user: null,
      devises: [],
      mounth: ["Janvier","Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"],
      profilersUsers: [],
      projetActif:null,
      souscripteursStoreSearch:[],
      souscripteur_store_search:"",
      souscritPay_store_search:"",
      souscritPay_search:"",
      souscritPay_search_mounth:"",
      souscritPay_search_date:"",
      souscripteurs_collect:[],
      projetActifPayments:[],
      form:{
        names:null,
        profiler_etat_civil:"Non défini",
        profiler_phone:null,
        profiler_email:null,
        profiler_sex:null,
        devise_id:null,
        projet_souscripteur_price_estimation:null,
        city_name:null,
        type_souscription_id:null,
        self_souscription:null,
        step_one:true,
        step_two:false
      },
      formData:{
        payment:null,
        payment_date:null,
        periodicity_id:null,
        projet_souscripteur_id:null,
        devise_id:null,
      },
      formCollectBeforePayment:[],
      requesting: false,
      step: 1,
      checkForm: false,
      lottieloading: false,
      settiming:null,
      step_load:false,
      step_store:false
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.gb_deleteCss();
    // this.gb_deleteJs();

    this.gb_loadReFreshUser();
    this.step_load = true;
    this.projet_actif();
    this.profilers_userS();
    this.all_devises();

    setInterval(() => {
      this.projet_actif();
      this.profilers_userS();
    }, 300000);
  },
  methods: {
    loadMountNow() {
      var mounthx = null;
      if (this.projetActif !=null) {
      mounthx =  this.projetActif?.periodicities.filter( (mnth)=> {
          return mnth.periodicity_title.toLowerCase().includes('octobre');
        });
      }

      if (mounthx.length > 0) {
        this.souscritPay_search_mounth = mounthx[0].periodicity_title
      }
      
    },

    async projet_actif() {
      this.lottieloading  = true;
      await axios.get(this.$store.state.app_url_dev + "projets_actif").then(({ data }) => {
       if (data.status == 1) {
          this.projetActif = data.data;
          this.souscripteursStoreSearch = this.projetActif.souscripteurs;
          this.projetActifPayments = data.paymentsFilter;
          this.loadMountNow();
          this.lottieloading  = false;
          this.step_load = false;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
          this.lottieloading  = false;
          this.step_load = false;
        }
      }).catch(function (error) {
       console.log(error);
        // this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
        //   timeout: 2000
        // });
        this.lottieloading  = false;
        this.step_load = false;
      });
    },

    async profilers_userS() {
      await axios.get(this.$store.state.app_url_dev + "users_profilers").then(({ data }) => {
       if (data.status == 1) {
          this.profilersUsers = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        // this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
        //   timeout: 2000
        // });
      });
    },

    async all_devises() {
      await axios.get(this.$store.state.app_url_dev + "devises").then(({ data }) => {
       if (data.status == 1) {
          this.devises = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async close() {
      this.step_store=false;
      this.souscripteur_store_search = "";
      this.souscripteurs_collect = [];
    },

    notificateRappel() {

      if (this.search_souscritNonPay_souscripteurs.length > 0) {

        var message = null;
        axios.get(this.$store.state.app_url_dev + "sms_partenaires").then(({ data }) => {
          if (data.status == 1) {
            this.dt_notifications = data.data;

            this.search_souscritNonPay_souscripteurs.forEach((element, index) => {
              if(data.data[0].sms_partenaire_rappel !=null && data.data[0].sms_partenaire_rappel !=""){
                message = data.data[0].sms_partenaire_rappel;
              }else{
                message = "Très chèr(e) partenaire.\n Merci de fidélité. Nous revenons vers vous pour un rappel sur la contribution du mois  :  " + this.souscritPay_search_mounth + ", qui s''élève à  " + element?.projet_souscripteur_price_estimation + " " + element?.devise?.devise_short + "/mois.\n Merci de donner pour des générations. Héritage d'influence 7KP.\n Contacts :+243828501407,heritage-influence@ce.church";
              }

              if(element?.identity?.profiler_phone.toString() !=null && element?.identity?.profiler_phone.toString().trim() !=""){
                
                this.gb_notifications_send(element?.identity?.profiler_phone.toString().trim(),message);
                
                if (index === this.search_souscritNonPay_souscripteurs.length - 1) {
                  this.toast.success("La notification de rappel aux souscripteurs a été envoyée avec succès.", {
                    timeout: 9500
                  });
                }
              }
              
            });

          } else {
            this.toast.error(data.message, {
              timeout: 6000
            });
          }
        }).catch(function (error) {
          console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });
      }
    },

    annuler_store(){
      this.step_store = !this.step_store;
      this.souscripteurs_collect =[];
    },


    reseRefreshForm() {
      this.form.names = null;
      this.form.profiler_etat_civil = "Non défini";
      this.form.profiler_phone = null;
      this.form.profiler_email = null;
      this.form.profiler_sex = null;
      this.form.devise_id = null;
      this.form.projet_souscripteur_price_estimation = null;
      this.form.city_name = null;
      this.form.type_souscription_id = null;
      this.form.self_souscription = null;
    },

    newsSouscripteur() {
      clearInterval(this.settiming);
      this.reseRefreshForm();
      this.form.step_one = true;
      this.form.step_two = false;
      this.lottieloading = false;
      this.settiming = null;
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },

    getNumberFormatSms(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value.substring(1, value.length);
        } else if (value.length == 12) {
          return  value;
        } else if (value.length == 10) {
          return '243' + value.substring(1, value.length);
        }
      }
    },
  },

  computed:{
    search_Pay_souscripteurs() {
      return this.projetActifPayments.filter((element)=>{
        return (element?.souscripteur?.identity?.profiler_prenom.trim().toLowerCase().includes(this.souscritPay_search.trim().toLowerCase()) || 
          element?.souscripteur?.identity?.profiler_nom.trim().toLowerCase().includes(this.souscritPay_search.trim().toLowerCase()) || 
          ((element?.souscripteur?.identity?.profiler_code !=null && element?.souscripteur?.identity?.profiler_code.trim() !='') ? element?.souscripteur?.identity?.profiler_code.trim().toLowerCase().includes(this.souscritPay_search.trim().toLowerCase()):'')) && (element?.periodicity?.periodicity_title.trim().toLowerCase().includes(this.souscritPay_search_mounth.toString().trim().toLowerCase())) && (element?.payment_date.includes(this.souscritPay_search_date));
      });
    },

    search_souscritNonPay_souscripteurs() {

      var FilterPays = [];
      var SouscripteursPaymentsSuccess = [];
      var dataSuscripteurNonPay = [];

      // this.projetActifPayments.forEach(element => {
      //   FilterPays.push(element.souscripteur);
      // });
      this.search_Pay_souscripteurs.forEach(element => {
        FilterPays.push(element.souscripteur);
      });


      if (FilterPays.length > 0) {
        SouscripteursPaymentsSuccess =   [...new Set(FilterPays)];

        if (SouscripteursPaymentsSuccess.length > 0) {
          for (let i = 0; i < this.souscripteursStoreSearch.length; i++) {
              for (let j = 0; j < SouscripteursPaymentsSuccess.length; j++) {
                if (SouscripteursPaymentsSuccess[j].projet_souscripteur_coding ==  this.souscripteursStoreSearch[i].projet_souscripteur_coding) {
                  break;
                } else {
                  if ((SouscripteursPaymentsSuccess[j].projet_souscripteur_coding != this.souscripteursStoreSearch[i].projet_souscripteur_coding) && j == SouscripteursPaymentsSuccess.length - 1 ) {
                    dataSuscripteurNonPay.push(this.souscripteursStoreSearch[i]);
                  }
                }
              }
            
          }
        } else {
          dataSuscripteurNonPay = this.souscripteursStoreSearch;
        }
      } else {
        dataSuscripteurNonPay = this.souscripteursStoreSearch;
      }
      return dataSuscripteurNonPay;
    },

    total_souscritPay_souscripteurs() {
      var elementsTotal = 0 ;

        this.search_Pay_souscripteurs.forEach(element => {
          elementsTotal  = parseInt(elementsTotal) + parseInt(element.payment);
        });
      return parseFloat(elementsTotal).toFixed(2);
    },

    total_globale_souscritPay_souscripteurs() {
      
      var elementsTotal = 0;
      if (this.projetActif != null && this.projetActifPayments.length > 0 && this.$store.state.tauxes != null && this.$store.state.tauxes.length > 0) {
        
        this.projetActifPayments.forEach(element => {
          var totalElement = 0;

          if (element?.devise?.devise_title.toString().toUpperCase() == "USD") {
            totalElement = element?.payment;
          } else if (element?.devise?.devise_title.toString().toUpperCase() == "CDF") {
            if(element?.taux !=null){
              totalElement = parseFloat(parseFloat(element?.payment) / parseFloat(element?.taux?.taux_price)).toFixed(2);
            }else{
              totalElement = parseFloat(parseFloat(element?.payment) / parseFloat(this.gb_devise_one_to_go_convert_two('usd', 'cdf')[0].taux_price)).toFixed(2);
            }

          } else if (element?.devise?.devise_title.toString().toUpperCase() == "EURO") {
            if(element?.taux !=null){
              totalElement = parseFloat(parseFloat(element?.payment) * parseFloat(element?.taux?.taux_price)).toFixed(2);
            }else{
              totalElement = parseFloat(parseFloat(element?.payment) * parseFloat(this.gb_devise_one_to_go_convert_two('euro', 'usd')[0].taux_price)).toFixed(2);
            }
          }

          elementsTotal  += parseFloat(totalElement);
        });

      }
      return parseFloat(elementsTotal).toFixed(2);
    },

    total_souscripteurs_7kp() {
      var elementsTotal = 0 ;

        this.souscripteursStoreSearch.forEach(element => {
          elementsTotal  = parseInt(elementsTotal) + parseInt(element.projet_souscripteur_price_estimation);
        });
      return parseFloat(elementsTotal).toFixed(2);
    },

    total_souscripteur_payment() {
      var listSouscrpteurs = [];
      var SouscripteursPaymentsSuccess = [];

      this.search_Pay_souscripteurs.forEach(element => {
        listSouscrpteurs.push(element.souscripteur);
      });

      if (listSouscrpteurs.length > 0) {
        console.log("suite")
        console.log(listSouscrpteurs)
        // SouscripteursPaymentsSuccess = [...new Set(listSouscrpteurs)];

         var jsonObject = listSouscrpteurs.map(JSON.stringify);
          var uniqueSet = new Set(jsonObject);
          var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
          SouscripteursPaymentsSuccess = uniqueArray;
      } 

      return SouscripteursPaymentsSuccess;
    }
  }
}
</script>
