<template>
  <div>
    <navbar-globale></navbar-globale>


    <div class="main-panel">
        <navbar-globale-mobile></navbar-globale-mobile>

        <div class="content">
          <div class="container-fluid">
              <div class="row">
                  <div v-if="this.projetActif !=null && this.projetActifPayments !=null && !this.step_store && !this.lottieloading && !this.step_load" class="col-md-12">
                      <div class="card">
                          <div class="header">
                              <div class="row">
                                  <div class="col-md-6">
                                      <h4 class="title">Derniers paiements</h4>
                                      <!-- <a href="http://" class="btn tbn-primary" @click.prevent="loadExcelSouscripteurs"> Excel Load</a> -->
                                      <p class="category"> {{this.projetActifPayments.length}} Paiements au total | {{this.projetActifPayments.length}} Paiements ce mois en cours</p>
                                    </div>
                                    <div v-if="this.$store.state.tauxes != null && this.$store.state.tauxes.length > 0" class="col-md-3 text-right">
                                      <p class="category">
                                      <strong v-if="this.gb_devise_one_to_go_convert_two('usd', 'cdf').length > 0 && this.$store.state.tauxes !=null" class="text-danger">1USD {{ this.gb_separateur_milliers_chiffre_grp_3(this.gb_devise_one_to_go_convert_two('usd','cdf')[0].taux_price) }} CDF</strong> <br/>
                                      <strong v-if="this.gb_devise_one_to_go_convert_two('euro', 'usd').length > 0 && this.$store.state.tauxes != null" class="text-danger">EURO {{ this.gb_separateur_milliers_chiffre_grp_3(this.gb_devise_one_to_go_convert_two('euro', 'usd')[0].taux_price) }} USD</strong> <br/>
                                      </p>
                                  </div>
                                  <div class="col-md-3">
                                      <button class="btn btn-default btn-block" data-toggle="modal" data-target=".modal-notif" @click.prevent="this.step_store=true">Mise à jour paiement</button>
                                  </div>
                              </div>

                          </div>
                          <div class="content table-responsive table-full-width">
                            <div class="row align-items-center" style="margin-bottom:3%;">
                              <div class="col-md-11">
                                <div class="justify-content-center align-items-center">
                                    <div class="col-md-3">
                                       <div class="row">
                                          <div class="col-md-12">
                                            <span class="category"> Rechercher souscripteur </span>
                                          </div>
                                          <div class="col-md-12">
                                            <input v-model="this.souscritPay_search" type="text" class="form-control border-input" placeholder="Rechercher souscripteur">
                                          </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <span class="category">Periode de P. </span>
                                        </div>
                                        <div class="col-md-12">
                                            <select v-model="this.souscritPay_search_mounth" :id="'periodicity_' + this.projetActif?.projet_coding" class="form-control border-input">
                                              <option value=""></option>
                                              <option  v-for="(periode, index) in projetActif?.periodicities.sort(function (a, b) { return a.id - b.id; })" :key="index" :value="periode.periodicity_title">{{ periode.periodicity_title.toUpperCase() }}</option>
                                            </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-3">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <span class="category">Date de paiement </span>
                                        </div>
                                        <div class="col-md-12">
                                          <input  type="date" v-model="this.souscritPay_search_date" class="form-control border-input" >
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 offset-md-1 text-right border border-danger">
                                      <span class="text-uppercase">Total des Paiement</span>  <br/>
                                      <span class="h4 text-danger"> $ {{ this.gb_separateur_milliers_chiffre_grp_3(this.total_souscritPay_souscripteurs) }}</span>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div style="height:450px; overflow-y: auto; margin-bottom:4%;">
                              <table class="table table-striped table-hover">
                                  <thead>
                                      <th>ID</th>
                                    <th>Souscripteur</th>
                                    <th>Montant</th>
                                    <th>Periode</th>
                                    <th>Date Paiem.</th>
                                  </thead>
                                  <tbody>
                                      <!-- <tr v-for="(payment, index) in projetActifPayments.slice(0,10)" :key="index" class="col"> -->
                                      <tr v-for="(payment, index) in search_Pay_souscripteurs" :key="index" class="col">
                                        <td>{{index + 1}}</td>
                                        <td> 
                                          <a href="" class="text-dark"  style="color:#1C1B1B;">{{ payment?.souscripteur?.identity?.profiler_prenom }} {{ (payment?.souscripteur?.identity?.profiler_nom !=null && payment?.souscripteur?.identity?.profiler_nom !="Non défini" ? payment?.souscripteur?.identity?.profiler_nom : '' )}}</a>
                                          <br>
                                          <strong class="small" v-if="payment?.souscripteur?.identity?.profiler_code !=null && payment?.souscripteur?.identity?.profiler_code.trim() !=''">{{payment?.souscripteur?.identity?.profiler_code}}</strong>
                                          <!-- <small>{{payment?.souscripteur?.identity?.minister?.minister_nom}}</small> -->
                                        </td>
                                        <!-- <td>{{this.gb_capitalisingFormat(payment?.souscripteur?.identity?.profiler_prenom)}} {{this.gb_capitalisingFormat(payment?.souscripteur?.identity?.profiler_nom)}} {{ payment?.souscripteur?.identity?.profiler_postnom !=null ? this.gb_capitalisingFormat(payment?.souscripteur?.identity?.profiler_postnom):' '}}</td> -->
                                        <td>{{payment?.devise?.devise_short}} {{ this.gb_separateur_milliers_chiffre_grp_3(payment?.payment) }}</td>
                                        <td>{{payment?.periodicity?.periodicity_title }}</td>
                                        <td>{{payment?.payment_date.toString().substring(0,10)}}</td>
                                      </tr>
                                      <!-- <tr class="col">
                                        <td>2</td>
                                        <td>Minerva Hooper</td>
                                        <td>$23,789</td>
                                        <td>Curaçao</td>
                                      </tr> -->
                                  </tbody>
                              </table>
                            </div>
                            <div v-if="this.search_Pay_souscripteurs.length > 10" class="row align-items-center" style="margin-bottom:3%;">
                                <div class="col-md-11 text-right">
                                  <span v-for="(pag, index) in projetActifPaymentsPg.links" :key="index"  class="badge text-dark mr-3" style="padding: 9px;margin-right: 5px;background-color: #9d9882;border-radius: 20px;"> {{ index== 0 ? 'Précédent' : (index == (parseInt(projetActifPaymentsPg.last_page) + 1)  ? ' Suivant ' : pag.label )  }}</span>
                                </div>
                                <div class="col-md-11 text-right">
                                  <small>Un total de {{ projetActifPaymentsPg.total }} {{ projetActifPaymentsPg.total > 1 ? ' eléments' : ' elément' }}</small>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
                  
                  <div v-if="this.step_store && !this.lottieloading && !this.step_load" class="col-md-11">
                      <div class="card">
                          <div class="header text-left">
                            <div class="row">
                              <div class="col-md-9">
                                <h3 class="title">Mise à jour Paiement</h3>
                                <p v-if="this.souscripteurs_collect.length > 0" class="category">{{this.souscripteurs_collect.length}} ajouts paiements avant sauvegarde</p>
                                <br>
                              </div>
                              <div class="col-md-3">
                                <button class="btn btn-default btn-block" data-toggle="modal" data-target=".modal-notif" @click.prevent="this.step_store=true">Mise à jour paiement</button>
                                <!-- <button class="btn btn-default btn-block" onclick="demo.showNotification('bottom','center')" data-toggle="modal" data-target=".modal-notif" @click.prevent="this.step_store=true">Mise à jour paiement</button> -->
                              </div>
                            </div>
                          </div>
                          <div class="content table-responsive table-upgrade">
                              <div class="row" style="margin-bottom:3%;">
                                <div class="col-md-12">
                                    <form class="m-4 text-left mt-m-5">
                                        <div class="justify-content-center">
                                            <div class="col-md-7">
                                                <div class="d-flex">
                                                    <input v-model="this.souscritPay_store_search" type="text" class="form-control border-input" placeholder="Rechercher">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                              </div>
                              <table class="table">
                                  <thead>
                                    <th>Souscripteur</th>
                                    <th>Détails</th>
                                    <th>Action</th>
                                  </thead>
                                  <tbody>
                                      <!-- <tr v-for="(souscritPay, index) in souscripteurs_collect" :key="index"> -->
                                      <tr v-for="(souscritPay, index) in search_souscritPay_souscripteurs" :key="index">
                                        <td class="col-md-5 text-uppercase">{{souscritPay.names}}
                                          <br>
                                          <strong class="small" v-if="souscritPay.souscripteur_code_user !=null && souscritPay.souscripteur_code_user.trim() !=''">{{souscritPay.souscripteur_code_user}}</strong>
                                        </td>
                                        <td class="col-md-4">
                                          <strong>
                                            {{parseFloat(souscritPay.payment).toFixed(2)}} {{souscritPay.devise_text}}
                                          </strong> <br>
                                          <small class="small text-uppercase" style="font-size:11px;">
                                            {{souscritPay.periodicity_text}}
                                          </small>,
                                          <small class="small mx-2">
                                            <i class="ti-calendar small"></i> {{souscritPay.payment_date}}
                                          </small>
                                        </td>
                                        <td class="col-md-2">
                                          <a href="#" class="btn btn-round btn-fill btn-danger small" style="padding: 2px 5px !important;" @click.prevent="deleteSouscripteurCollect(souscritPay.souscripteur_projet_id)"><i class="ti-minus"></i></a>
                                        </td>
                                      </tr>
                                  </tbody>
                              </table>
                               <div class="row" style="margin-bottom:3%;">
                                  <div class="col-md-11 text-right">
                                      <a v-if="this.souscripteurs_collect.length > 0 && !this.lottieloading" href="#" class="btn btn-round btn-fill btn-default float-right mx-2" style="margin:0 5px !important;" @click.prevent="annuler_store">Annuler</a>
                                      <a v-if="this.souscripteurs_collect.length > 0 &&  !this.lottieloading" @click.prevent="storeSouscripteurPayment" target="_blank" href="#" class="btn btn-round btn-fill btn-info">Sauvegarder</a>
                                      <div class="text-center">
                                        <lottie-player v-if="this.lottieloading" class="text-center"
                                          :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'" background="transparent" speed="1" style="width: 100%; height: 90px;" loop
                                          autoplay></lottie-player>
                                        <span v-if="this.lottieloading && this.step_store" class="small secondary">Traitement en cours</span>
                                      </div>
                                  </div>
                                </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                <div class="text-center">
                  <lottie-player v-if="this.lottieloading && this.step_load" class="text-center"
                    :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'" background="transparent" speed="1" style="width: 100%; height: 90px;" loop
                    autoplay></lottie-player>
                </div>
              </div>
          </div>
        </div>

        <footer-globale></footer-globale>
    </div>

    <div class="modal fade modal-notif modal-slide" tabindex="-1 " role="dialog " aria-labelledby="defaultModalLabel " aria-hidden="true " >
      <div class="modal-dialog modal-md" role="document ">
          <div class="modal-content ">
              <div class="modal-header ">
                  <h5 class="modal-title  text-uppercase" id="defaultModalLabel">Nouveau Paiement</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeVerif()">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body ">
                  <div class="mb-4" style="margin-bottom:3%;">
                    <div class="row justify-content-center" style="margin-bottom: 15px;">
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Periode de paiement <span class="text-danger">*</span></span>
                          </div>
                          <div class="col-md-12">
                              <select v-model="this.formData.periodicity_id" :id="'periodicity_'+ this.projetActif?.projet_coding" class="form-control border-input">
                                <option  v-for="(periode, index) in projetActif?.periodicities.sort(function(a,b){return a.id - b.id;})" :key="index" :value="periode.id">{{periode.periodicity_title.toUpperCase()}}</option>
                              </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Date Paiement <span class="text-danger">*</span></span>
                          </div>
                          <div class="col-md-12">
                              <div class="d-flex">
                                  <input v-model="this.formData.payment_date" type="date" class="form-control border-input">
                              </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-4">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Devise de paiement <span class="text-danger">*</span></span>
                          </div>
                          <div class="col-md-12">
                              <select v-model="this.formData.devise_id" class="form-control border-input">
                                <option  v-for="(devise, index) in devises" :key="index" :value="devise.id">{{devise.devise_title}}</option>
                              </select>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                          <input type="text" class="form-control border-input" placeholder="Search souscripteur"  v-model="this.souscripteur_store_search"  @keyup.prevent="search_store_souscripteurs">
                        </div>
                    </div>
                  </div>
                  <div class="content table-responsive table-upgrade" style="height:450px; overflow-y: auto;">
                      <table class="table table-striped table-hover">
                          <thead>
                              <!-- <th>ID</th> -->
                              <th style="font-weight: normal;font-size:0.9em;">Souscripteur</th>
                              <!-- <th>..</th>
                              <th>...</th> -->
                          </thead>
                          <tbody>
                              <tr v-for="(souscripteur, index) in souscripteursStoreSearch" :key="index">
                                  <!-- <td>1</td> -->
                                  <td  class="row align-items-center grid-container" style="padding: 8px 8px !important;font-size: 12px;">
                                      <div class="col-md-6">
                                          <span>{{souscripteur?.identity?.profiler_prenom.toUpperCase()}} {{souscripteur?.identity?.profiler_nom.toUpperCase() == "NON DÉFINI" ? " ": souscripteur?.identity?.profiler_nom.toUpperCase() }}</span>
                                          <br>
                                          <strong v-if="souscripteur?.identity?.profiler_code !=null" class="small">{{souscripteur?.identity?.profiler_code}} </strong>
                                          <!-- <span>{{this.gb_capitalisingFormat(souscripteur?.identity?.profiler_prenom)}} {{this.gb_capitalisingFormat(souscripteur?.identity?.profiler_nom)}} {{ souscripteur?.identity?.profiler_postnom !=null ? this.gb_capitalisingFormat(souscripteur?.identity?.profiler_postnom) : ''}}</span> -->
                                      </div>
                                      <div class="col-md-5">
                                          <div class="d-flex" style="display: flex !important;">
                                              <input type="number" class="form-control border-input input_select" style="margin-right: 10px !important;font-size: 12px;" :id="souscripteur?.projet_souscripteur_coding">
                                              <select name="" :id="'devise_'+souscripteur?.projet_souscripteur_coding" class="form-control border-input px-2" style="font-size: 12px;">
                                                <option  v-for="(devise, index) in devises" :key="index" :value="devise.id">{{devise.devise_title}}</option>
                                              </select>
                                          </div>
                                      </div>
                                      <div class="col-md-1">
                                          <button type="button" class="btn btn-success btn-fill" style="padding: 2px 5px !important;" @click.prevent="select_souscripteur_before_collect_store(souscripteur?.projet_souscripteur_coding)"><i class="ti-plus"></i></button>
                                      </div>
                                  </td>
                                  <!-- <td>
                                      <button type="submit" class="btn btn-success btn-fill" style="padding: 2px 5px !important;"><i class="ti-check"></i></button>
                                  </td> -->
                              </tr>
                              <!-- <tr>
                                  <td>6</td>
                                  <td>Mason Porter</td>
                                  <td>$78,615</td>
                                  <td>Chile</td>
                              </tr> -->
                          </tbody>
                      </table>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right">
                      <button class="btn btn-default"  @click.prevent="close()"  type="button" data-dismiss="modal" aria-label="Close" style="margin-right: 4%;">Annuler </button>
                      <button class="btn btn-default" @click.prevent="closeVerif()"    type="button" data-dismiss="modal" aria-label="Close">Terminer</button>
                    </div>
                  </div>
              </div>
              <!-- <div class="modal-footer ">
                  <button type="button " class="btn btn-secondary btn-block " data-dismiss="modal ">Ajouter</button>
              </div> -->
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarGlobale from './layouts/NavBar.vue';
import NavbarGlobaleMobile from './layouts/NavBarMobile.vue';
import FooterGlobale from './layouts/FooterGlobale.vue';

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'PaymentPage',
  components:{NavbarGlobale,FooterGlobale,NavbarGlobaleMobile},
  data() {
    return {
      user: null,
      devises: [],
      profilersUsers: [],
      dt_taux:[],
      projetActif:null,
      projetActifPaymentsPg:[],
      souscripteursStoreSearch:[],
      souscripteur_store_search:"",
      souscritPay_store_search:"",
      souscritPay_search:"",
      souscritPay_search_mounth:"",
      souscritPay_search_date:"",
      souscripteurs_collect:[],
      projetActifPayments:[],
      loadExcelPayment:[],
      form:{
        names:null,
        profiler_etat_civil:"Non défini",
        profiler_phone:null,
        profiler_email:null,
        profiler_sex:null,
        devise_id:null,
        projet_souscripteur_price_estimation:null,
        city_name:null,
        type_souscription_id:null,
        self_souscription:null,
        step_one:true,
        step_two:false
      },
      formData:{
        payment:null,
        payment_date:null,
        periodicity_id:null,
        projet_souscripteur_id:null,
        devise_id:null,
      },
      formCollectBeforePayment:[],
      requesting: false,
      step: 1,
      checkForm: false,
      lottieloading: false,
      settiming:null,
      step_load:false,
      step_store:false
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.gb_deleteCss();
    // this.gb_deleteJs();

    this.gb_loadReFreshUser();
    this.step_load = true;
    this.projet_actif();
    this.profilers_userS();
    this.all_devises();
    this.gd_refresh_taux();
    
    setInterval(() => {
      this.projet_actif();
      this.profilers_userS();
      this.gd_refresh_taux();
    }, 300000);
  },
  methods: {

    async projet_actif() {
      this.lottieloading  = true;
      await axios.get(this.$store.state.app_url_dev + "projets_actif").then(({ data }) => {
       if (data.status == 1) {
          this.souscritPay_search ="";
          this.souscritPay_search_mounth ="";
          this.souscritPay_search_date ="";
          this.souscritPay_search ="";
          this.projetActif = data.data;
          this.souscripteursStoreSearch = this.projetActif.souscripteurs;
          this.projetActifPayments = data.paymentsFilter;
          this.projetActifPaymentsPg = data.paymentsFilterPg;
          this.lottieloading  = false;
          this.step_load = false;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
          this.lottieloading  = false;
          this.step_load  = false;
        }
      }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
        this.lottieloading  = false;
        this.step_load  = false;
      });
    },

    loadExcelSouscripteurs() {
      console.log("alors");
      this.data_excel_store();

      //  setTimeout(() => {
      //    this.toast.info("Création des souscripteurs éffectrué avec success  !!!", {
      //     timeout: 2000
      //   });

      //   this.projet_actif();
      //   this.profilers_userS();
      // }, 60000);
    },

    async profilers_userS() {
      await axios.get(this.$store.state.app_url_dev + "users_profilers").then(({ data }) => {
       if (data.status == 1) {
          this.profilersUsers = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async all_devises() {
      await axios.get(this.$store.state.app_url_dev + "devises").then(({ data }) => {
       if (data.status == 1) {
          this.devises = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async all_taux() {
      await axios.get(this.$store.state.app_url_dev + "taux").then(({ data }) => {
        if (data.status == 1) {
          this.dt_taux = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
      }).catch(function (error) {
        console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    sumPayments() {
     if(this.projetActif !=null && this.projetActifPayments.length > 0) {
      var total = 0;
      this.projetActifPayments.forEach(element => {
        total += parseFloat(element?.payment);
      });

      return total.toFixed(2);
     }
    },
    async search_store_souscripteurs() {
      if(this.souscripteur_store_search.toString() !=null && this.souscripteur_store_search.toString().trim() !="" && this.souscripteur_store_search.trim().length >= 2 && this.projetActif.souscripteurs.length > 0) {
        this.souscripteursStoreSearch = this.projetActif.souscripteurs.filter((element)=>{
          return element.identity.profiler_prenom.trim().toLowerCase().includes(this.souscripteur_store_search.trim().toLowerCase()) || element.identity.profiler_nom.trim().toLowerCase().includes(this.souscripteur_store_search.trim().toLowerCase()) || (element.identity.profiler_code !=null ? element.identity.profiler_code.trim().toLowerCase().includes(this.souscripteur_store_search.trim().toLowerCase()):"");
        });
      }else{
        this.souscripteursStoreSearch = this.projetActif.souscripteurs;
      }
    },

    async close() {
      this.step_store=false;
      this.souscripteur_store_search = "";
      this.souscripteurs_collect = [];
    },

    async closeVerif() {
      if (this.souscripteurs_collect.length == 0) {
        this.close();
      }
    },

    select_souscripteur_before_collect_store(souscripteur_id) {
      if(souscripteur_id.toString() !=null && souscripteur_id.toString().trim() !="" && this.souscripteursStoreSearch.length > 0) {
       var souscripteur_collect = this.souscripteursStoreSearch.find((souscript)=>souscript.projet_souscripteur_coding.toString().trim() == souscripteur_id.toString().trim());
       if(souscripteur_collect !=null){

        var inputVal = document.getElementById(souscripteur_collect.projet_souscripteur_coding);
        var DeviseVal = document.getElementById('devise_'+souscripteur_collect.projet_souscripteur_coding);
        var PeriodeVal = document.getElementById('periodicity_'+ this.projetActif.projet_coding);

        if(inputVal!=null && inputVal.value !=null && inputVal.value.toString().trim()!=""
        && DeviseVal!=null && DeviseVal.value !=null && DeviseVal.value.toString().trim()!=""
        && PeriodeVal!=null && PeriodeVal.value !=null && PeriodeVal.value.toString().trim()!=""){

          
          var taux_id ="";
          if(DeviseVal.options[DeviseVal.selectedIndex].text == "CDF"){
            taux_id = this.gb_devise_one_to_go_convert_two('usd', 'cdf')[0].id;
          }else if(DeviseVal.options[DeviseVal.selectedIndex].text == "USD"){
            taux_id =null;
          } else if(DeviseVal.options[DeviseVal.selectedIndex].text == "EURO"){
            taux_id =this.gb_devise_one_to_go_convert_two('euro', 'usd')[0].id;
          }

          var objectData = {
            "payment":inputVal.value,
            "periodicity_id":this.formData.periodicity_id,
            "periodicity_text":PeriodeVal.options[PeriodeVal.selectedIndex].text,
            "projet_souscripteur_id":souscripteur_collect.id,
            "payment_date":this.formData.payment_date,
            "devise_id":DeviseVal.value,
            "devise_text":DeviseVal.options[DeviseVal.selectedIndex].text,
            "names":this.gb_capitalisingFormat(souscripteur_collect?.identity?.profiler_prenom) +" "+ this.gb_capitalisingFormat(souscripteur_collect?.identity?.profiler_nom) +" "+ (souscripteur_collect?.identity?.profiler_postnom !=null ? this.gb_capitalisingFormat(souscripteur_collect?.identity?.profiler_postnom) : ""),
            "souscripteur_code_user":souscripteur_collect?.identity?.profiler_code,
            "souscripteur_projet_id":souscripteur_collect?.projet_souscripteur_coding,
            "phone":souscripteur_collect?.identity?.profiler_phone,
            "price_souscription":souscripteur_collect?.projet_souscripteur_price_estimation,
            "devise_souscription":souscripteur_collect?.devise?.devise_title,
            "taux_id":taux_id
          }
          this.souscripteurs_collect.push(objectData);
          document.getElementById(souscripteur_collect.projet_souscripteur_coding).value = "";
          this.souscripteur_store_search ="";

        }else{
          if(DeviseVal ==null || DeviseVal.value  ==null || DeviseVal.value.toString().trim()==""
        || PeriodeVal ==null || PeriodeVal.value  ==null || PeriodeVal.value.toString().trim()==""){
            this.toast.info("Veuillez completer les champs manquants", {
              timeout: 2000
            });
          }else{
            this.toast.info("Veuillez renseigné le montant du paiement", {
              timeout: 2000
            });
          }
        }
       }
      }
    },

    storeSouscripteurPayment() {
      if(this.souscripteurs_collect.length > 0) {

        this.lottieloading = true;

        this.souscripteurs_collect.forEach((element,index) => {

          axios.post(this.$store.state.app_url_dev + "payment_souscripteur",element).then(({ data }) => {
            if (data.status == 1) {

              var collect_souscripteur_pay = [];
              var total_collect_souscripteur_pay = 0;
              var restePrice  = 0;
              var souscripteur_identity = null;

              collect_souscripteur_pay = this.search_Pay_souscripteurs.filter((suscript) => {
                return suscript.projet_souscripteur_id == element.projet_souscripteur_id;
              });

              console.log(collect_souscripteur_pay);


              if (element.devise_text == "USD") {
                total_collect_souscripteur_pay +=parseFloat(element.payment);
              }else if(element.devise_text == "CDF"){
                total_collect_souscripteur_pay +=parseFloat(element.payment) / (this.gb_devise_one_to_go_convert_two('usd', 'cdf')[0].taux_price);
              }else if(element.devise_text == "EURO"){
                total_collect_souscripteur_pay +=parseFloat(element.payment) * (this.gb_devise_one_to_go_convert_two('euro', 'usd')[0].taux_price);
              }

            if (collect_souscripteur_pay.length > 0) {
              collect_souscripteur_pay.forEach(price_pay => {
                if (price_pay?.devise?.devise_title =="USD") {
                  total_collect_souscripteur_pay += parseFloat(price_pay?.payment);
                }else if(price_pay?.devise?.devise_title == "CDF"){
                  total_collect_souscripteur_pay +=parseFloat(price_pay?.payment) / (this.gb_devise_one_to_go_convert_two('usd', 'cdf')[0].taux_price);
                }else if(price_pay?.devise?.devise_title == "EURO"){
                  total_collect_souscripteur_pay +=parseFloat(price_pay?.payment) * (this.gb_devise_one_to_go_convert_two('euro', 'usd')[0].taux_price);
                }
              })
            }

            souscripteur_identity =  this.souscripteursStoreSearch.filter((elmt) => {
              return elmt.projet_souscripteur_coding == element.souscripteur_projet_id;
            });
            console.log("user acess");
            console.log(souscripteur_identity);

              // v0
              // var message = "Très chèr(e) partenaire.\n Merci de votre paiement de "+element.payment+" "+element.devise_text+ " sur la souscription de "+ element.price_souscription+" "+element.devise_souscription +"/mois.\n Merci de donner pour des générations. Héritage d'influence 7KP.\n Contacts :+243828501407,heritage-influence@ce.church";
              // v1
              // var message = (souscripteur_identity[0]?.identity?.profiler_sex.toString().trim() == "M" ? "Mr " : "Mme ") +(souscripteur_identity[0]?.identity?.profiler_prenom) + (souscripteur_identity[0]?.identity?.profiler_nom != null && souscripteur_identity[0]?.identity?.profiler_nom != "Non défini" ? ' '+ souscripteur_identity[0]?.identity?.profiler_nom : '')+", nous avons recu votre paiement de "+ this.gb_separateur_milliers_chiffre_grp_3(element.payment)+" "+element.devise_text+ ". Votre solde actuel est de "+ this.gb_separateur_milliers_chiffre_grp_3( parseFloat(total_collect_souscripteur_pay).toFixed(2))  +" USD pour le projet Héritage d'influence 7KP.\n Que Dieu vous benisse.\n Contacts :+243828501407,heritage-influence@ce.church";
              // var messaged = (souscripteur_identity[0]?.identity?.profiler_sex.toString().trim() == "M" ? "Cher frère " : "Chère soeur ") +(souscripteur_identity[0]?.identity?.profiler_prenom) + (souscripteur_identity[0]?.identity?.profiler_nom != null && souscripteur_identity[0]?.identity?.profiler_nom != "Non défini" ? ' '+ souscripteur_identity[0]?.identity?.profiler_nom : '')+", nous avons recu votre paiement de "+ this.gb_separateur_milliers_chiffre_grp_3(element.payment)+" "+element.devise_text+ ". Votre solde actuel est de "+ this.gb_separateur_milliers_chiffre_grp_3( parseFloat(total_collect_souscripteur_pay).toFixed(2))  +" USD pour le projet ouvriers partenaires.\n Que Dieu vous benisse.\n Contacts :+243828501407,heritage-influence@ce.church";
              var message = null;

              axios.get(this.$store.state.app_url_dev + "sms_partenaires").then(({ data }) => {
              if (data.status == 1) {
                this.dt_notifications = data.data;

                  if(data.data[0].sms_partenaire_souscription !=null && data.data[0].sms_partenaire_souscription !=""){
                    
                    restePrice = parseFloat(souscripteur_identity[0]?.projet_souscripteur_price_estimation) - parseFloat(total_collect_souscripteur_pay);
                    // message =  (souscripteur_identity[0]?.identity?.profiler_sex.toString().trim() == "M" ? "Cher frère " : "Chère soeur ") +(souscripteur_identity[0]?.identity?.profiler_prenom) + (souscripteur_identity[0]?.identity?.profiler_nom != null && souscripteur_identity[0]?.identity?.profiler_nom != "Non défini" ? ' '+ souscripteur_identity[0]?.identity?.profiler_nom : '')+". "+ data.data[0].sms_partenaire_add_price +" Paiement :"+this.gb_separateur_milliers_chiffre_grp_3(parseFloat(element.payment).toFixed(2))+" "+element.devise_text+ " Période :"+element.periodicity_text+" Solde :"+ this.gb_separateur_milliers_chiffre_grp_3(parseFloat(total_collect_souscripteur_pay).toFixed(2))  +" USD";
                    message =  (souscripteur_identity[0]?.identity?.profiler_sex.toString().trim() == "M" ? "Cher frère " : "Chère soeur ") +(souscripteur_identity[0]?.identity?.profiler_prenom != null && souscripteur_identity[0]?.identity?.profiler_prenom != "Non défini" ? ' '+ souscripteur_identity[0]?.identity?.profiler_prenom : '') + (souscripteur_identity[0]?.identity?.profiler_nom != null && souscripteur_identity[0]?.identity?.profiler_nom != "Non défini" ? ' '+ souscripteur_identity[0]?.identity?.profiler_nom : '')+". "+ data.data[0].sms_partenaire_add_price+ " Période :"+element.periodicity_text+ ".  Paiement :"+this.gb_separateur_milliers_chiffre_grp_3(parseFloat(element.payment).toFixed(2))+" "+element.devise_text+".  Solde :"+ this.gb_separateur_milliers_chiffre_grp_3(parseFloat(restePrice).toFixed(2))  +" USD.  Balance Total :"+ this.gb_separateur_milliers_chiffre_grp_3(parseFloat(total_collect_souscripteur_pay).toFixed(2))  +" USD";
                  }else{
                    message = "Merci beaucoup pour votre générosité envers les élèves et étudiants nécessiteux.\n Votre soutien, au nom de l'Église Phila CE, est inestimable !.";
                    // message = "Bonjour " + (this.form.profiler_sex.toString().trim() == "M" ? "Mr " : "Mme ") + (this.form.names.toString().trim().toUpperCase()) + ".\n Nous vous remercions pour votre elan de coeur envers Phila CE. Et que Dieu vous donne les moyens d'honorer votre contribution.";
                  }

                  if(element?.phone.toString() !=null && element?.phone.toString().trim() !=""){
                    // this.gb_notifications_send(this.form.profiler_phone.toString().trim(),message);
                    this.gb_notifications_send(element?.phone.toString().trim(),message);
                    
                    setTimeout(() => {
                      if(souscripteur_identity[0]?.projet_souscripteur_price_estimation == total_collect_souscripteur_pay){
                        var message_end =  (souscripteur_identity[0]?.identity?.profiler_sex.toString().trim() == "M" ? "Cher frère " : "Chère soeur ") +(souscripteur_identity[0]?.identity?.profiler_prenom != null && souscripteur_identity[0]?.identity?.profiler_prenom != "Non défini" ? ' '+ souscripteur_identity[0]?.identity?.profiler_prenom : '') + (souscripteur_identity[0]?.identity?.profiler_nom != null && souscripteur_identity[0]?.identity?.profiler_nom != "Non défini" ? ' '+ souscripteur_identity[0]?.identity?.profiler_nom : '')+". Nous vous remercions pour avoir honorer votre engagement en faveur de Betuzolana 2024. Que Dieu vous bénisse. Solde total :"+ this.gb_separateur_milliers_chiffre_grp_3(parseFloat(total_collect_souscripteur_pay).toFixed(2))  +" USD";
                        this.gb_notifications_send(element?.phone.toString().trim(),message_end);
                      }
                    }, 2000);
                  }


              } else {
                this.toast.error(data.message, {
                  timeout: 6000
                });
              }
            }).catch(function (error) {
              console.log(error);
              this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                timeout: 2000
              });
            });

              // let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(element.phone.toString().trim());
              // let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=test&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());
    
              // axios.get(urlgetsms).then(() => { });
              // this.gb_notifications_send(elementd?.phone.toString().trim(),messaged);


              if(index == (this.souscripteurs_collect.length -1)){
                setTimeout(() => {
                  this.souscripteurs_collect =[];
                  this.step_store = false;
                  this.lottieloading = false;
                  this.projet_actif();
                  this.profilers_userS();
                  this.toast.success("La sauvegarde est éffectuée avec  success !!!", {
                    timeout: 7500
                  });
                }, 10000);
              }
            } else {
              // this.toast.error(data.message, {
              //   timeout: 6000
              // });
              setTimeout(() => {
                this.souscripteurs_collect = [];
                this.step_store = false;
                this.lottieloading = false;
                this.toast.success("La sauvearde n'a pas abouti, veuillez réessayer !!!", {
                  timeout: 9000
                });
              }, 15000);
            }
          }).catch(function (error) {
            console.log(error);
            this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
              timeout: 2000
            });
          });

        });

      }else{
        this.toast.error("Veuillez mentionner les souscripteurs et leurs paiement pour la sauvegarde", {
          timeout: 5000
        });
      }
    },

    deleteSouscripteurCollect(souscripteur_projet_id){
      if(this.souscripteurs_collect.length > 0){
        this.souscripteurs_collect = this.souscripteurs_collect.filter((sousrit)=>{
          return sousrit.souscripteur_projet_id != souscripteur_projet_id;
        })

        if(this.souscripteurs_collect.length == 0){
          this.annuler_store();
        }
      }
    },

    annuler_store(){
      this.step_store = !this.step_store;
      this.souscripteurs_collect =[];
    },


    async storeSouscripteur() {

      if(this.form.self_souscription !=null ){
        this.form.type_souscription_id = 2;
      }else{
        this.form.type_souscription_id = 1;
      }

      if(this.form.names.trim() !=null && this.form.names.toString().trim() !=null && this.form.profiler_phone.trim() !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation.trim() !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex.trim() !=null && this.form.profiler_sex.toString().trim() !=null) {
      //  var mod = this.modules.find((mod)=>mod.id.toString().trim() == mod_id.toString().trim());

        await axios.post(this.$store.state.app_url_dev + "souscripteur",this.form).then(({ data }) => {
        if (data.status == 1) {
          var message = "Bonjour " + (this.form.profiler_sex.toString().trim() == "M" ? "Mr " : "Mme ") + (this.form.names.toString().trim().toUpperCase()) + ".\n Votre souscription vient d'être fait pour le projet 7000 Partenaires. (Phila-c.e)";


          let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());
          // let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=test&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());

          axios.get(urlgetsms).then(() => { });
          // this.$store.state.user_auth = data;
          // this.$store.state.user_store = data;
          // setTimeout(() => {
          //     this.refreshForm();
          //     this.step = 1;
          //     this.lottieloading = false;
          //   }, 15001);

          setTimeout(() => {
            this.form.step_one = false;
            this.form.step_two = true;
            this.lottieloading = false;
            this.reseRefreshForm();

            this.settiming = setInterval(() => {
              this.newsSouscripteur();
            }, 6000);

          }, 4500);
          } else {
            // this.toast.error(data.message, {
            //   timeout: 6000
            // });
          }
        }).catch(function (error) {
        console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });
      }else{
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    to_submit_form() {
      // if (this.form.names.toString().trim() !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex.toString().trim() !=null) {
      if(this.form.names !=null && this.form.names.toString().trim() !=null && this.form.profiler_phone !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex !=null && this.form.profiler_sex.toString().trim() !=null) {
        this.lottieloading = true;
        // if (this.Online()) {
          this.storeSouscripteur();
          // this.RegisterDemandUser();
           // } else {
        //     setTimeout(() => {
        //     this.lottieloading = false
        //   }, 2500);
        // }
      } else {
        this.checkForm = true;
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    reseRefreshForm() {
      this.form.names = null;
      this.form.profiler_etat_civil = "Non défini";
      this.form.profiler_phone = null;
      this.form.profiler_email = null;
      this.form.profiler_sex = null;
      this.form.devise_id = null;
      this.form.projet_souscripteur_price_estimation = null;
      this.form.city_name = null;
      this.form.type_souscription_id = null;
      this.form.self_souscription = null;
    },

    newsSouscripteur() {
      clearInterval(this.settiming);
      this.reseRefreshForm();
      this.form.step_one = true;
      this.form.step_two = false;
      this.lottieloading = false;
      this.settiming = null;
    },





    async  all_modules() {
      await axios.get(this.$store.state.app_url_dev + "modules").then(({ data }) => {
       if (data.status == 1) {
          this.modules = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async  all_levels() {
      await axios.get(this.$store.state.app_url_dev + "levels").then(({ data }) => {
       if (data.status == 1) {
          this.levels = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async  all_cities() {
      await axios.get(this.$store.state.app_url_dev + "cities").then(({ data }) => {
       if (data.status == 1) {
          this.cities = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
      }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async  all_subjectRdvs() {
      await axios.get(this.$store.state.app_url_dev + "subjects").then(({ data }) => {
       if (data.status == 1) {
          this.subjectRdvs = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async all_orgas() {
      await axios.get(this.$store.state.app_url_dev + "orgas").then(({ data }) => {
       if (data.status == 1) {
          this.orgas = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async search_all_orgas() {
      this.all_orgas();
      if(this.formF.title.toString() !=null && this.formF.title.toString().trim() !="" && this.formF.title.trim().length >= 2 && this.orgas.length > 0) {
        this.searchOrgas = this.orgas.filter((orga)=>{
          return orga.title.trim().toLowerCase().includes(this.formF.title.trim().toLowerCase());
        });
      }else{
        this.searchOrgas =[];
      }
    },

    select_orga(id) {
      if(id.toString() !=null && id.toString().trim() !="" &&this.orgas.length > 0) {
       var orga = this.orgas.find((org)=>org.id.toString().trim() == id.toString().trim());
       if(orga !=null){
        this.formF.title = orga.title;
        this.formF.person_name = orga.person_name;
        this.formF.phone_one = orga.phone_one;
        this.formF.email = orga.email;
        this.formF.adress = orga.adress;
        this.searchOrgas =[];
       }
      }
    },


    access_step_two() {
      if (this.form.identity_firstname != null && this.form.identity_firstname.trim() != "" && this.form.identity_middlename != null && this.form.identity_middlename.trim() != "" && this.form.identity_sex != null && this.form.identity_sex.trim() != "" && this.form.tranche_age_id != null && this.form.tranche_age_id.toString().trim() != "") {
        this.lottieloading = true;
        // if (this.Online()) {
          setTimeout(() => {
            this.step = 2;
            this.lottieloading = false
          }, 3000);
        // } else {
        //     setTimeout(() => {
        //     this.lottieloading = false
        //   }, 2500);
        // }
      } else {
        this.checkForm = true;
        if (this.form.identity_middlename == null || this.form.identity_middlename.trim() == "") {
            this.toast.error("Veuillez compléter le nom", {
              timeout: 5000
            });
        } else {
          this.toast.error("Veuillez compléter les champs manquants", {
            timeout: 5000
          });
        }
      }
    },


    changesexe(typesexe) {
      if (this.isSexe) {
        this.isSexe = false;
      } else {
        this.isSexe = true;
      }
      this.form.identity_sex = typesexe;
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },

    getNumberFormatSms(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value.substring(1, value.length);
        } else if (value.length == 12) {
          return  value;
        } else if (value.length == 10) {
          return '243' + value.substring(1, value.length);
        }
      }
    },
  },

  computed:{
    search_souscritPay_souscripteurs() {
      return this.souscripteurs_collect.filter((element)=>{
        return element.names.trim().toLowerCase().includes(this.souscritPay_store_search.trim().toLowerCase()) || 
        element.phone.trim().toLowerCase().includes(this.souscritPay_store_search.trim().toLowerCase());
      });
    },
   

    search_Pay_souscripteurs() {
      return this.projetActifPayments.filter((element)=>{
        return (element?.souscripteur?.identity?.profiler_prenom.trim().toLowerCase().includes(this.souscritPay_search.trim().toLowerCase()) || 
        element?.souscripteur?.identity?.profiler_nom.trim().toLowerCase().includes(this.souscritPay_search.trim().toLowerCase()) || ((element?.souscripteur?.identity?.profiler_code !=null && element?.souscripteur?.identity?.profiler_code.trim()!='') ? element?.souscripteur?.identity?.profiler_code.trim().toLowerCase().includes(this.souscritPay_search.trim().toLowerCase()):'')) && (element?.periodicity?.periodicity_title.trim().toLowerCase().includes(this.souscritPay_search_mounth.trim().toLowerCase())) && (element?.payment_date.includes(this.souscritPay_search_date));
      });
    },

    total_souscritPay_souscripteurs() {
      var elementsTotal = 0 ;

      if (this.search_Pay_souscripteurs.length > 0 && this.$store.state.tauxes != null && this.$store.state.tauxes.length > 0) {
        this.search_Pay_souscripteurs.forEach(element => {
  
         var totalElement = 0;
  
         if (element?.devise?.devise_title.toString().toUpperCase() == "USD") {
          totalElement = element?.payment;
        } else if (element?.devise?.devise_title.toString().toUpperCase() == "CDF") {
          if(element?.taux !=null){
            totalElement = parseFloat(parseFloat(element?.payment) / parseFloat(element?.taux?.taux_price)).toFixed(2);
          }else{
            totalElement = parseFloat(parseFloat(element?.payment) / parseFloat(this.gb_devise_one_to_go_convert_two('usd', 'cdf')[0].taux_price)).toFixed(2);
          }
        } else if (element?.devise?.devise_title.toString().toUpperCase() == "EURO") {
          if(element?.taux !=null){
            totalElement = parseFloat(parseFloat(element?.payment) * parseFloat(element?.taux?.taux_price)).toFixed(2);
          }else{
            totalElement = parseFloat(parseFloat(element?.payment) * parseFloat(this.gb_devise_one_to_go_convert_two('euro', 'usd')[0].taux_price)).toFixed(2);
          }
        }
          elementsTotal  = parseFloat(elementsTotal) + parseFloat(totalElement);
        });
      }
      return parseFloat(elementsTotal).toFixed(2);
    },
  }
}
</script>
